import React from 'react';

import SEO from '../components/SEO';
// @ts-ignore

const IndexPage: React.FC = () => (
  <div>
    <SEO title="ManaMed LLC" />
  </div>
);

export default IndexPage;
